import React from "react";
import styled from "styled-components";
import get from "lodash/get";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import Banner from "../components/Banner";
import Section from "../sections/Section";
import Contact from "../sections/Contact";

import RaisedContent from "../components/RaisedContent";

const Wrapper = styled.div`
  overflow: hidden; // important
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Map = styled.iframe`
  border: 0;
  width: 100%;
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
  filter: gray;
  filter: grayscale(99%);
  backface-visibility: hidden;
`;

const RootIndex = (props) => {
  const href = get(props, "location.href");
  return (
    <Layout location={props.location}>
      <div style={{ background: "#fff" }}>
        <Helmet>
          <title>{`Contact Us | Diamantidou School of Music, Dance & Drama`}</title>
          {href && <link rel="canonical" href={href} />}
          <meta
            name="description"
            content={
              "Diamantidou School of Music, Dance & Drama offers a wide choice of various directions in arts and talent development. Our classes range from music, dance and theatrical workshops as well as gym, modelling and taekwondo. For our younger students we offer afternoon creative classes."
            }
          />
        </Helmet>
        <Banner title={"Get in touch"} imageUrl={`/banner.jpg`} />
        <RaisedContent>
          <Wrapper>
            <Section title={`Contact us`} fullWidth>
              <Contact />
            </Section>
            <Section>
              <Map
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2061.2117471965157!2d32.997094487076666!3d34.66901267461017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14e7322483998b17%3A0x7b5d0a225e5eb572!2sDiamantidou%20School%20of%20Music%2C%20Dance%20and%20Drama!5e0!3m2!1sen!2suk!4v1618771932384!5m2!1sen!2suk"
                width="100%"
                height="450"
                loading="lazy"
              ></Map>
            </Section>
          </Wrapper>
        </RaisedContent>
      </div>
    </Layout>
  );
};

export default RootIndex;
